function calculateMonths(
    startdatetime: string,
    enddatetime: string
) {
    const monthIndices = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const startMonthIndex = parseInt(startdatetime.slice(5, 7));
    const endMonthIndex = parseInt(enddatetime.slice(5, 7));
    const startYear = parseInt(startdatetime.slice(0, 4));
    const endYear = parseInt(enddatetime.slice(0, 4));

    let months = [];
    let monthIndexInRange = false;
    for (let i = 0; i < monthIndices.length; i++) {
        // console.log(i, monthIndexInRange)
        if (monthIndices[i] === startMonthIndex) {
            monthIndexInRange = true;
        }
        if (monthIndexInRange) {
            months.push(monthNames[monthIndices[i] - 1])
        }
        if (
            (monthIndices[i] === endMonthIndex &&
                monthIndexInRange === true)
            &&
            (startYear === endYear ||
                months.length > 1)) {
            break;
        }
    }

    // console.log(months);
    return months;

}

export default calculateMonths;