import React from 'react';
import AutoAddressCompleteResult from './AutoAddressCompleteResult';
import MapboxClient from 'mapbox';
import $ from 'jquery';
import { debounce } from 'throttle-debounce';
import _ from 'underscore';
import { useForm } from "react-hook-form";
// import style from './style.scss';

class AutoAddressComplete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            matchedItems: [],
            activeItem: null,
            selectedItem: null,
            address: "",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.client = new MapboxClient(process.env.REACT_APP_MAPBOX_TOKEN);
        this.fetchPlaces = debounce(200, this.fetchPlaces);

    }

    handleChange(event) {
        this.setState({ address: event.target.value });
        if (event.target.value.length > 6)
            this.fetchPlaces(event.target.value);
        else {
            this.setState({
                matchedItems: [],
                selectedItem: null,
                activeItem: null
            });
        }
    }

    fetchPlaces(value) {
        const searchText = value.toLowerCase();
        this.client.geocodeForward(searchText, { limit: 3, types: "address", country: "CA" }, function (err, res) {
            this.setState({ searchText: searchText, matchedItems: res.features, activeItem: null, selectedItem: null });
        }.bind(this));
    }

    handleKeyDown(e) {
        const activeItem = this.state.activeItem ? this.state.activeItem : null;
        switch (e.which) {
            case 40:
                e.preventDefault();
                if (activeItem && activeItem.length > 0) {
                    if ($(activeItem).next().length == 0) return;
                    $(activeItem).removeClass('selected');

                    this.setState({ activeItem: $(activeItem).next() }, function () {
                        this.state.activeItem.addClass('selected');
                    });

                } else {
                    const firstItem = $('.places-litm-el li:nth-child(1)');
                    this.setState({ activeItem: firstItem }, function () {
                        this.state.activeItem.addClass('selected');
                    });
                }

                break;
            case 38:
                e.preventDefault();
                if (activeItem && activeItem.length > 0) {
                    if ($(activeItem).prev().length == 0) return;
                    $(activeItem).removeClass('selected');

                    this.setState({ activeItem: $(activeItem).prev() }, function () {
                        this.state.activeItem.addClass('selected')
                    });
                } else {
                    this.setState({ activeItem: $('.places-litm-el li:nth-child(1)') }, function () {
                        this.state.activeItem.addClass('selected')
                    });
                }

                break;
            case 13:
                e.preventDefault();
                if (!activeItem) break;
                const selectedItem = _.findWhere(this.state.matchedItems, { id: activeItem.attr('id') });
                this.setState({
                    matchedItems: [],
                    selectedItem: selectedItem
                });
                break;
            case 27:
                e.preventDefault();
                this.setState({
                    matchedItems: [],
                    selectedItem: null,
                    activeItem: null
                });
                break;
        }

    }

    handleItemClick(item) {
        this.setState({
            matchedItems: [],
            selectedItem: item
        });
        //this.props.onSelect(item);
        //     document.getElementById("address").value = item.place_name;
        const cityObject = item.context.find(place => place.id.substring(0, 5) === "place");
        const countryObject = item.context.find(place => place.id.substring(0, 7) === "country");
        this.props.onSelect(cityObject.text, countryObject.text, item.place_name, item.geometry.coordinates);
        this.setState({
            address: item.place_name
        });

    }

    render() {
        let results;
        if (!this.state.selectedItem) {
            results =
                <AutoAddressCompleteResult
                    searchText={this.state.searchText}
                    items={this.state.matchedItems}
                    handleClick={this.handleItemClick}
                />
        }

        // let selectedPlace;
        // let selectedCoordinates;
        // let inputBox;
        // if (this.state.selectedItem) {
        //    // selectedPlace = <code>{this.state.selectedItem.place_name + " " + this.state.selectedItem.geometry.coordinates}</code>;
        //     // inputBox = <input
        //     //     onKeyDown={this.handleKeyDown}
        //     //     type="text"
        //     //     className="input-lg auto-complte-search-input form-control w-full"
        //     //     placeholder="Search"
        //     //     onChange={this.handleChange}
        //     //     value={this.state.selectedItem.place_name}

        //     // />
        // }
        // else {
        //     inputBox = <input
        //         onKeyDown={this.handleKeyDown}
        //         type="text"
        //         className="input-lg auto-complte-search-input form-control w-full"
        //         placeholder="Search"
        //         onChange={this.handleChange}
        //     />
        // }
        return (
            <div className="row">
                <p className="flex font-medium text-lg text-green-700 py-1">📍 Address*</p>
                <p className={'text-gray-700 font-normal text-sm'}>Type & select address</p>
                <p className={'text-gray-700 font-normal text-sm'}>Enter "920 King Street West, Kingston, Ontario K7M 9G9, Canada" for virtual opportunities</p>
                <input
                    id="address"
                    name="address"
                    onKeyDown={this.handleKeyDown}
                    type="text"
                    className="input-lg auto-complete-search-input form-control w-full focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    // defaultValue="200 Lonsdale Road, Toronto, Ontario M4V 1W6, Canada"
                    defaultValue=""
                    placeholder="Canada's Wonderland, 1 Canada's Wonderland Dr, Vaughan, Ontario L6A 4V3, Canada"
                    onChange={this.handleChange}
                    //value={(this.state.selectedItem && this.state.selectedItem.place_name) }
                    value={this.state.address}
                />
                {results}
            </div>
        );
    }
}

export default AutoAddressComplete;
