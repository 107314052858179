import React from 'react';
// import style from './style.scss';
import style from './style.css'

const AutoAddressCompleteResultItem = (props) => {

    return (
        <li 
            id={props.id}
            class="AutoAddressCompleteResultItem"
            onClick={props.selectPlace.bind(null, props.id)
        }>
            {props.placeName}
        </li>
    );
}

export default AutoAddressCompleteResultItem;