const months = [
  "Jan",
  "Feb",
  "Ma",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec"
];

function formatDate(date: string | undefined) {
  if (date) {
    return months[parseInt(date.substring(5, 7)) - 1] + " " + date.substring(8).replace("0", "") + ", " + date.substring(0, 4)
  }
  return ""
}

export default formatDate;