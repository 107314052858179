import formatDate from "./formatDate"

const todaydatetime = new Date();
const dd = String(todaydatetime.getDate()).padStart(2, '0');
const mm = String(todaydatetime.getMonth() + 1).padStart(2, '0'); //January is 0!
const yyyy = todaydatetime.getFullYear();

const todaynumeric = yyyy + '-' + mm + '-' + dd; 
const nextyearnumeric = yyyy + 1 + '-' + mm + '-' + dd;
const todaytext = formatDate(todaynumeric);
const todayint = parseInt(yyyy + mm + dd);

// console.log(todaynumeric,nextyearnumeric,todaytext,todayint)
export {todaydatetime,todaynumeric,nextyearnumeric,todaytext,todayint};