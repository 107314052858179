import filterStats from "./filterStats"
import { indexName, searchClient } from "../../../lib/algoliaClient";
import EmailReport from "./EmailReport";

const index = searchClient.initIndex(indexName);

function SubmitOpportunity(newObject: any) {
    const filteredObject: any = filterStats(newObject);
    const profanityCount = filteredObject.profanityCount;
    const profanityScore = filteredObject.profanityScore;
    const isClean = filteredObject.isClean;

    let objStatus;
    if (isClean) {
        objStatus = "success";
    }
    else if (profanityCount < 5 && profanityScore < 3) {
        objStatus = "review";
    }
    else {
        objStatus = "blocked";
    } 
    newObject.status = objStatus;

    newObject.moderated = false;
    newObject.moderator = "";
    newObject.moderatordate = "";

    index
        .saveObject(newObject)
        .wait()
        .then((response) => {
        });

    if (objStatus === "success") {
        return 1;
    }
    else if (objStatus === "review") {
        EmailReport(newObject, true, "⚠️ REVIEW - CLEAN", profanityScore, profanityCount);
        EmailReport(newObject, false, "⚠️ REVIEW - RAW", profanityScore, profanityCount);
        return 2;
    }
    else {
        EmailReport(newObject, true, "❌ BLOCKED - CLEAN", profanityScore, profanityCount);
        EmailReport(newObject, false, "❌ BLOCKED - RAW", profanityScore, profanityCount);
        return 3;
    }
}

export default SubmitOpportunity;
