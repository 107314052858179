import { GeoHit } from "../../types/StoreHit";

const css = "select-none bg-white text-green-500 hover:text-green-900 cursor-pointer block py-2 px-3 rounded-full ";
const css2 = "select-none bg-white text-green-700 hover:text-green-900 cursor-pointer block py-2 px-3 rounded-full ";

const MenuButton = ({
    name,
    text,
    onClick,
    currentModal,
    onClick2,
}: {
    name: string;
    text?: string;
    onClick: (name: string | null) => void;
    currentModal: string | null;
    onClick2: (currentStore: GeoHit | null) => void;
}
) => {
    return (
        <li>
            <a className=
                {name === "Category" || name === "Attendance" ? css : css2}
                onClick={() => {
                    // toggle modal off
                    if (name === currentModal) {
                        onClick(null);
                    }
                    // set currentModal to name
                    else {
                        onClick(name);
                    }
                    onClick2(null)
                }}>
                {text ? text : name}
            </a>
        </li>
    )
};

export default MenuButton;