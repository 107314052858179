import filter from "./filterFunction"

function filterStats(newObject: any) {
    let isClean = true;
    let profaneWords = 0;
    let totalWords = 0;
    const objectValues = Object.values(newObject)

    for (let i = 0; i < objectValues.length; i++) {
        const inputString = objectValues[i];
        if (typeof inputString === 'string' || inputString instanceof String) {
            const inputWords = inputString.split(" ");
            if (filter.isProfane(inputString)) {
                isClean = false;
                // console.log(inputString)
                // console.log(filter.clean(inputString))
            }
            for (let j = 0; j < inputWords.length; j++) {
                if (filter.isProfane(inputWords[j])) {
                    profaneWords ++;
                }
                totalWords++;
            }
        }
    }

    return ({
        // cleanObject: filter.clean(JSON.stringify(newObject)),
        profanityCount: profaneWords,
        profanityScore: profaneWords / totalWords * 100,
        isClean: isClean
    })
}

export default filterStats;