import React, { useState, Component } from 'react';
import ReactMapboxGl, { ZoomControl, Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {Map} from 'mapbox-gl';
import {connectGeoSearch} from 'react-instantsearch-dom';
import {GeoSearchProvided} from 'react-instantsearch-core'
import {LngLat} from "../../types/LngLat";

// import MarkerUnselected from "../../assets/city.png";
// import {ReactComponent as MarkerUnselectedRed} from "../../assets/healthcare-marker.svg";
import {ReactComponent as MarkerUnselected} from "../../assets/marker.svg";
import {ReactComponent as MarkerUnselectedRed} from "../../assets/marker-red.svg";
import {ReactComponent as MarkerSelected} from "../../assets/marker-selected.svg";

import { ReactComponent as AcademicMarker } from "../../assets/colours/academic-marker.svg";
import { ReactComponent as ArtsAndCultureMarker } from "../../assets/colours/arts-and-culture-marker.svg";
import { ReactComponent as AthleticMarker } from "../../assets/colours/athletic-marker.svg";
import { ReactComponent as CharityMarker } from "../../assets/colours/charity-marker.svg";
import { ReactComponent as ChildrenAndYouthMarker } from "../../assets/colours/children-and-youth-marker.svg";
import { ReactComponent as EnvironmentalMarker } from "../../assets/colours/environmental-marker.svg";
import { ReactComponent as HealthcareMarker } from "../../assets/colours/healthcare-marker.svg";
import { ReactComponent as SeniorsMarker } from "../../assets/colours/seniors-marker.svg";
// import { ReactComponent as uccMarker } from "../../assets/colours/ucc-marker.svg";

// import {ReactComponent as MarkerSelected} from "../../assets/healthcare-marker.svg";
//import SearchAsMoving from "./SearchAsMoving";
import {comparePosition} from "../../lib/comparePosition";
import { GeoHit } from '../../types/StoreHit';


interface MapState {
  lat: number|undefined,
  lng: number|undefined,
  zoom: number,
  markers: LngLat[]
  isUserInteraction: boolean,
  userInteractionEnabled: boolean
  currentStore: LngLat | null
}

interface MapProps {
  currentLngLat: LngLat | null,
  currentStore: LngLat | null,
  onClickMarker: (marker: LngLat, hits: GeoHit[]) => void,
  // centre: LngLat | null
}

const ReactMap = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN as string,
});

const categoryToSVG: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  '🎓 academic': AcademicMarker,
  '🎨 arts and culture': ArtsAndCultureMarker,
  '🏀 athletic': AthleticMarker,
  '🎁 charity': CharityMarker,
  '🍭 children & youth': ChildrenAndYouthMarker,
  // '🌿 environmental': EnvironmentalMarker,
  '🏥 healthcare': HealthcareMarker,
  '🧑‍🦳 seniors': HealthcareMarker,
  // Add more categories and corresponding SVG components as needed
};

class MapComponent extends Component<GeoSearchProvided & MapProps, MapState> {
  map: any;
  
  state = {
    lat: 43,
    lng: -79,
    zoom: 9,
    center: [-79.47935598929897, 43.65051639781275],
    markers: [],
    currentStore: this.props.currentLngLat || [-79 , 43],
    // currentStore: [-79 , 43],
    isUserInteraction: true,
    userInteractionEnabled: true
  };
  
  constructor(props: GeoSearchProvided & MapProps) {
    super(props);
    this.centerMapOnCoordinates = this.centerMapOnCoordinates.bind(this);
    this.onMapInteraction = this.onMapInteraction.bind(this);
  }

  componentWillReceiveProps(nextProps: Readonly<GeoSearchProvided & MapProps>) {
    const {hits, currentLngLat, currentStore} = nextProps
    const markers: LngLat[] = hits.map(
      ({ _geoloc }: { _geoloc: { lat: number; lng: number } }) => {
        return [_geoloc.lng, _geoloc.lat];
      }
    );

    //initially, generate hits by IP address, after, by map interaction or by autocomplete input
    if (markers.length != 0 || currentLngLat?.[0] != 99){

      if (this.state.isUserInteraction ){
        this.setState({ isUserInteraction: false }, () => {
          this.centerMapOnCoordinates(currentLngLat?.[0] == 99 ? markers[0]: currentLngLat)});
      }
      else{

        if (this.state.lng != currentLngLat?.[0]){
          this.setState({ lng: currentLngLat?.[0], lat: currentLngLat?.[1] }, () => {
            this.centerMapOnCoordinates(currentLngLat?.[0] == 99 ? markers[0]: currentLngLat)});
        }
      }

     // onClickMarker(markers[0]);

      this.setState({markers}, () => {
         //  console.log(`setState(${markers}`)
          })

      this.setState({currentStore}, () => {
         //   console.log("setState({currentStore}")
          })
    }
  }

centerMapOnCoordinates(coordinates: LngLat|null) {
  this.map.flyTo({
    essential: false,
    center: coordinates,
    // center: [-79, 43],
    zoom: 10,
  })
}
  onMapInteraction(map: Map) {
    if (this.state.userInteractionEnabled) {
      const { refine } = this.props;

      refine({
        northEast: map.getBounds().getNorthEast(),
        southWest: map.getBounds().getSouthWest(),
      });
    }

    const newCenter = this.map.getCenter();
    // this.setState({ center: any[] = [newCenter.lng, newCenter.lat] });
    this.setState(prevState => ({
      ...prevState,
      center: [newCenter.lng, newCenter.lat]
    }));
  }

  render() {

    return (
      <div className={'h-full w-full relative'}>
        <div className={'h-full w-full'}>
          <ReactMap
            ref={(e) => {
              this.map = e?.state.map;
            }}
            style="mapbox://styles/mapbox/streets-v11"
            containerStyle={{
              height: '100%',
              width: '100%',
              position: 'relative',
              display: 'block',
              overflow: 'hidden'
              }
            }
            onMoveEnd={this.onMapInteraction}
            // center={[-79.3832,43.6532]}
            center={this.state.center as [number, number]}
            // center={this.onMapInteraction}
            // center={this.props.currentLngLat?.[0] == 99 ? markers[0]: currentLngLat}
            // initialViewState={{
            //   longitude: -122.4,
            //   latitude: 37.8,
            //   zoom: 14
            // }}
          >
            <>
              {
                // this.state.markers.map((marker: LngLat) => {
                //   return <Marker coordinates={marker} onClick={()=>this.props.onClickMarker(marker, this.props.hits)}>
                //     {comparePosition(marker, this.state.currentStore) 
                //     ? <MarkerSelected/> 
                //     : 
                //     // currentStore.category.includes("🏥 healthcare")
                //     <MarkerUnselected className="cursor-pointer"/>}
                //   </Marker>
                // })

                // this.state.markers.map((marker: LngLat, index: number) => {
                //   const hit: GeoHit = this.props.hits[index]; // Assuming hits correspond one-to-one with markers
                //   let markerComponent = <MarkerUnselected className="cursor-pointer" />;
              
                //   if (hit && hit.category) {
                //     if (hit.category.includes('🏥 healthcare')) {
                //       markerComponent = <MarkerUnselectedRed className="cursor-pointer" />;
                //     } else {
                //       markerComponent = <MarkerUnselected className="cursor-pointer" />;
                //     }
                //   }
              
                //   return (
                //     <Marker coordinates={marker} onClick={() => this.props.onClickMarker(marker, this.props.hits)}>
                //       {comparePosition(marker, this.state.currentStore) ? <MarkerSelected /> : markerComponent}
                //     </Marker>
                //   );
                // })

                this.state.markers.map((marker: LngLat, index: number) => {
                  const hit: GeoHit = this.props.hits[index]; // Assuming hits correspond one-to-one with markers
                  let markerComponent = <MarkerUnselected className="cursor-pointer" />;
              
                  if (hit && hit.category && hit.category.length > 0 && categoryToSVG[hit.category[0]]) {
                    const CategorySVGComponent = categoryToSVG[hit.category[0].toLowerCase()];
                    markerComponent = <CategorySVGComponent className="cursor-pointer" />;
                  } else {
                    // Default marker if category not found or undefined
                    markerComponent = <MarkerUnselected className="cursor-pointer" />;
                  }
              
                  return (
                    <Marker coordinates={marker} onClick={() => this.props.onClickMarker(marker, this.props.hits)}>
                      {comparePosition(marker, this.state.currentStore) ? <MarkerSelected /> : markerComponent}
                    </Marker>
                  );
                })
              }
            {/* <ZoomControl position={'top-right'} /> */}

            </>
          </ReactMap>
          {/* 
          <SearchAsMoving checked={this.state.userInteractionEnabled} onChange={(userInteractionEnabled) => this.setState({userInteractionEnabled})}/>
          */}
        </div>

      </div>
    );
  }
}

export default connectGeoSearch(MapComponent)