import { GeoHit } from "../../types/StoreHit";
import checklistArray from "../Modals/AddOpportunityModal/checklistArray.json"
import DeleteOpportunity from "../Modals/AddOpportunityModal/DeleteOpportunity";
import EmailReport from "../Modals/AddOpportunityModal/EmailReport";
import filterStats from "../Modals/AddOpportunityModal/filterStats";
import formatDate from "../App/formatDate"
import { indexName, searchClient } from "../../lib/algoliaClient";
import { useState } from 'react';
import { useForm } from "react-hook-form";

const index = searchClient.initIndex(indexName);
const analytics_index = searchClient.initIndex("helpmap_user_analytics");

let liked: boolean = false;

type ReviewType = {
	review: string;
	reviewer: string;
	stars: number;
	reviewdate: string;
};


function report(object: any) {
	let problem = prompt("Please state the problem:", "Wrong address");
	if (problem != null) {
		alert(`Thank you for reporting.
The post will be reviewed shortly.`);

		//DeleteOpportunity(object.objectID!);
		const filteredObject: any = filterStats(object);
		const profanityCount = filteredObject.profanityCount;
		const profanityScore = filteredObject.profanityScore;

		EmailReport(object, true, `REPORT - CLEAN - ${problem}`, profanityScore, profanityCount);
		EmailReport(object, false, `REPORT - RAW - ${problem}`, profanityScore, profanityCount);
	}
}

const StoreDetails = ({
	currentStore,
	onClick
}: {
	currentStore: GeoHit | null;
	onClick: () => void;
}): JSX.Element => {
	// let [showlikes, setShowlikes] = useState(currentStore?.likes != null && currentStore.likes != 0 ? currentStore?.likes : 0);

	const onSubmit = (data: ReviewType) => {

		const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
		const date = new Date();
		const dateStamp = (months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear())
		
		const reviewObject = {
			review: data.review,
			reviewer: data.reviewer,
			stars: data.stars,
			reviewdate: dateStamp
		}

		const filteredObject: any = filterStats(reviewObject);
		const isClean = filteredObject.isClean;
		
		if (isClean) {
			

			if (
				reviewObject.stars &&
				1 <= reviewObject.stars && reviewObject.stars <= 5
			) {
				alert("Thanks for reviewing. Refresh to see your review.");
				index.partialUpdateObject({
					reviews: {
						_operation: 'Add',
						value: reviewObject
					},
					totalstars: {
						_operation: 'Increment',
						value: Number(reviewObject.stars)
					},
					numreviews: {
						_operation: 'Increment',
						value: 1
					},
					objectID: currentStore?.objectID
				});

			}

			else {
				alert("Stars must be from 1 to 5");
			}

			(document.getElementById("form") as HTMLFormElement).reset();
		}

		else {
			alert("Your review may be unsafe. Try to rewrite your review without any sensitive words.");
		}

	}

	const { register, handleSubmit, formState: { errors } } = useForm<ReviewType>({});

	return (
		<div className={'m-2'}>
			<article>
				<button type="button"
					className="absolute cursor-pointer right-0 bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
					onClick={() => onClick()}>
					<span className="sr-only">Close menu</span>

					<svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
					</svg>

				</button>

				<div className="p-4">
					<h2 className={'font-medium text-lg'}>{currentStore?.organization}</h2>
					<p className={'font-normal text-sm'}>
						{currentStore?.volunteerposition}
					</p>
					<p className={'font-normal text-sm'}>
						{currentStore?.location}
					</p>

					{/* <br className={`${currentStore?.image ? "" : "hidden"}`}></br> */}
					<div>
						<img src={currentStore?.image}
							className={`${currentStore?.image ? "" : "hidden"} object-cover h-48 w-full my-3`}
						></img>
					</div>

					<p className={'font-medium text-md'}>Share</p>

					<div className="sharing-buttons flex flex-wrap">

						<a className="border-2 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-green-600 bg-green-600 hover:bg-green-500 hover:border-green-500" target="_blank" rel="noopener"
							href={
								'mailto:?subject='
								+ currentStore?.organization
								+ ' Volunteer Opportunity: '
								+ currentStore?.volunteerposition
								+ '&body='
								+ "Hello, %0A%0A"
								+ currentStore?.organization + ' has a '
								+ currentStore?.volunteerposition + ' service opportunity at '
								+ currentStore?.location
								+ '! %0AYou can learn more at HelpMap, a service platform for students: '
								+ window.location.origin
								+ '/' + currentStore?.objectID
								+ '%0A%0AHope you enjoy volunteering!'
							}
							onClick={() => {
								// update opportunity engagement statistics
								index.partialUpdateObject({
									emailclicks: {
										_operation: 'Increment',
										value: 1
									},
									totalclicks: {
										_operation: 'Increment',
										value: 1
									},
									objectID: currentStore?.objectID
								})

								analytics_index.partialUpdateObject({
									total_all_clicks: {
										_operation: 'Increment',
										value: 1
									},
									total_email_clicks: {
										_operation: 'Increment',
										value: 1
									},
									objectID: "master_all_clicks"
								})

								analytics_index.partialUpdateObject({
									master_click_times: {
										_operation: 'Add',
										value: [
											currentStore?.organization,
											String(new Date()).split(" ").slice(0, 5).join(" ").slice(0, -3),
											currentStore?.objectID,
											"Email"
										]
									},
									objectID: "master_social_clicks"
								})
							}}
							aria-label="Share by Email" draggable="false">
							<svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4">
								<title>Email</title>
								<path d="M464 64a48 48 0 0 1 29 86L275 314c-11 8-27 8-38 0L19 150a48 48 0 0 1 29-86h416zM218 339c22 17 54 17 76 0l218-163v208c0 35-29 64-64 64H64c-35 0-64-29-64-64V176l218 163z">
								</path>
							</svg>
						</a>

						{/* <a className="border-2 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-green-600 bg-green-600 hover:bg-green-500 hover:border-green-500" target="_blank" rel="noopener"
							href={
								'https://twitter.com/intent/tweet?text='
								+ currentStore?.organization + ' has a '
								+ currentStore?.volunteerposition + ' service opportunity at '
								+ currentStore?.location
								+ '\n\n\n'
								+ '!&hashtags=helpmap,volunteer,student&url='
								+ window.location.origin
								+ '/'
								+ currentStore?.objectID
							}
							onClick={() => {
								// update opportunity engagement statistics
								index.partialUpdateObject({
									twitterclicks: {
										_operation: 'Increment',
										value: 1
									},
									totalclicks: {
										_operation: 'Increment',
										value: 1
									},
									objectID: currentStore?.objectID
								})

								analytics_index.partialUpdateObject({
									total_all_clicks: {
										_operation: 'Increment',
										value: 1
									},
									total_twitter_clicks: {
										_operation: 'Increment',
										value: 1
									},
									objectID: "master_all_clicks"
								})

								analytics_index.partialUpdateObject({
									master_click_times: {
										_operation: 'Add',
										value: [
											currentStore?.organization,
											String(new Date()).split(" ").slice(0, 5).join(" ").slice(0, -3),
											currentStore?.objectID,
											"Twitter"
										]
									},
									objectID: "master_social_clicks"
								})
							}}
							aria-label="Share on Twitter" draggable="false">
							<svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4">
								<title>Twitter</title>
								<path d="m459 152 1 13c0 139-106 299-299 299-59 0-115-17-161-47a217 217 0 0 0 156-44c-47-1-85-31-98-72l19 1c10 0 19-1 28-3-48-10-84-52-84-103v-2c14 8 30 13 47 14A105 105 0 0 1 36 67c51 64 129 106 216 110-2-8-2-16-2-24a105 105 0 0 1 181-72c24-4 47-13 67-25-8 24-25 45-46 58 21-3 41-8 60-17-14 21-32 40-53 55z">
								</path>
							</svg>
						</a> */}

						<button className="border-2 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-green-600 bg-green-600 hover:bg-green-500 hover:border-green-500" aria-label="Copy Link" draggable="false"
							onClick={() => {
								navigator.clipboard.writeText(window.location.origin + '/' + currentStore?.objectID).then(() => {
									alert("Shareable link copied!");
								})

								// update opportunity engagement statistics
								index.partialUpdateObject({
									copylinkclicks: {
										_operation: 'Increment',
										value: 1
									},
									totalclicks: {
										_operation: 'Increment',
										value: 1
									},
									objectID: currentStore?.objectID
								})

								analytics_index.partialUpdateObject({
									total_all_clicks: {
										_operation: 'Increment',
										value: 1
									},
									total_copy_link_clicks: {
										_operation: 'Increment',
										value: 1
									},
									objectID: "master_all_clicks"
								})

								analytics_index.partialUpdateObject({
									master_click_times: {
										_operation: 'Add',
										value: [
											currentStore?.organization,
											String(new Date()).split(" ").slice(0, 5).join(" ").slice(0, -3),
											currentStore?.objectID,
											"Copy Link"
										]
									},
									objectID: "master_social_clicks"
								})
							}}>

							<svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-4 h-4">
								<title>Copy Link</title>
								<path d="M12.11,15.39,8.23,19.27a2.47,2.47,0,0,1-3.5,0,2.46,2.46,0,0,1,0-3.5l3.88-3.88a1,1,0,1,0-1.42-1.42L3.31,14.36a4.48,4.48,0,0,0,6.33,6.33l3.89-3.88a1,1,0,0,0-1.42-1.42Zm-3.28-.22a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.92-4.92a1,1,0,1,0-1.42-1.42L8.83,13.75A1,1,0,0,0,8.83,15.17ZM21,18H20V17a1,1,0,0,0-2,0v1H17a1,1,0,0,0,0,2h1v1a1,1,0,0,0,2,0V20h1a1,1,0,0,0,0-2Zm-4.19-4.47,3.88-3.89a4.48,4.48,0,0,0-6.33-6.33L10.47,7.19a1,1,0,1,0,1.42,1.42l3.88-3.88a2.47,2.47,0,0,1,3.5,0,2.46,2.46,0,0,1,0,3.5l-3.88,3.88a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0Z" />
							</svg>
						</button>


					</div>

					<div className="sharing-buttons flex flex-wrap">
						{/* <button className="transform hover:scale-125 border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-green-600 bg-green-600 hover:bg-green-500 hover:border-green-500" aria-label="Like" draggable="false"
							onClick={() => {
								if (!liked){
									setShowlikes(showlikes + 1);
								}
								else {
									setShowlikes(showlikes - 1);
								}

								// toggle liked status
								liked = !liked;
								console.log(liked);

								// update opportunity engagement statistics
								index.partialUpdateObject({
									likes: {
										_operation: 'Increment',
										value: liked ? 1 : -1
										// value: 1
									},
									objectID: currentStore?.objectID
								})

								analytics_index.partialUpdateObject({
									total_all_clicks: {
										_operation: 'Increment',
										value: liked ? 1 : -1
										// value: 1
									},
									total_likes: {
										_operation: 'Increment',
										value: liked ? 1 : -1
										// value: 1
									},
									objectID: "master_all_clicks"
								})

								analytics_index.partialUpdateObject({
									master_click_times: {
										_operation: 'Add',
										value: [
											currentStore?.organization,
											String(new Date()).split(" ").slice(0, 5).join(" ").slice(0, -3),
											currentStore?.objectID,
											"Like"
										]
									},
									objectID: "master_social_clicks"
								})
							}}>

							<svg aria-hidden="true" fill={liked ? "green" : "currentColor"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-4 h-4">
								{/* <svg aria-hidden="true" fill={`${liked ? "green" : "currentColor"}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-4 h-4"> */}
						{/* <title>Like</title>
								<path d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
							</svg>
						</button> */}
						{/* <p className="text-lg mr-2">{`${currentStore?.likes ? 
																currentStore?.likes + (liked ? 1 : 0) : 
																liked ? 1 : ""}`+ " "} </p> */}
						{/* <p className="text-lg mr-2">{showlikes} </p> */}


						{/* <button className="transform hover:scale-125 border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-1 rounded text-white border-green-600 bg-green-600 hover:bg-green-500 hover:border-green-500" aria-label="Dislike" draggable="false"
								onClick={() => {
									
									// update opportunity engagement statistics
									index.partialUpdateObject({
										dislikes: {
											_operation: 'Increment',
											value: 1
										},
										objectID: currentStore?.objectID
									})

									analytics_index.partialUpdateObject({
										total_all_clicks: {
										_operation: 'Increment',
										value: 1
										},
										total_dislike_clicks: {
										_operation: 'Increment',
										value: 1
										},
										objectID: "master_all_clicks"
									})
		
									analytics_index.partialUpdateObject({
									master_click_times: {
										_operation: 'Add',
										value: [
										currentStore?.organization,
										String(new Date()).split(" ").slice(0,5).join(" ").slice(0,-3),
										currentStore?.objectID,
										"Dislike"
										]
									},
									objectID: "master_social_clicks"
									})
								}}>

								<svg aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-4 h-4">
									<title>Dislike</title>
									<path d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384" />
								</svg>

							</button>
							<p className="text-lg">{`${currentStore?.dislikes ? currentStore?.dislikes : ""}`}</p> */}
					</div>

					{/* <div className={currentStore?.organization === 'TAC Sports' ? '' : 'hidden'}>
						<iframe
							className="hidden sm:block object-cover h-48 w-full my-5"
							// width="400" 
							// height="400" 
							src="https://www.youtube-nocookie.com/embed/W5xtaKSYFB0?&autoplay=0&mute=1&playlist=W5xtaKSYFB0&loop=1&controls=0&modestbranding=1&rel=0"
							title="Camp Overview - TAC Sports"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen>
						</iframe>
					</div>
					<br className={`${currentStore?.website ? "" : "hidden"}`}></br> */}

					{/* <iframe width="420" height="315"
        src="https://youtu.be/OypYcQKwVUE">
        </iframe> */}

					<a
						target="_blank"
						className="transform hover:scale-110 duration-100 ease inline-flex items-center transition bg-green-100 font-bold text-green-800 p-2 rounded-md"
						href={currentStore?.website}
						onClick={() => {
							// update opportunity engagement statistics
							index.partialUpdateObject({
								volunteerclicks: {
									_operation: 'Increment',
									value: 1
								},
								volunteerclicktimes: {
									_operation: 'Add',
									value: String(new Date()).split(" ").slice(0, 5).join(" ")
								},
								totalclicks: {
									_operation: 'Increment',
									value: 1
								},
								objectID: currentStore?.objectID
							})

							analytics_index.partialUpdateObject({
								total_all_clicks: {
									_operation: 'Increment',
									value: 1
								},
								total_volunteer_clicks: {
									_operation: 'Increment',
									value: 1
								},
								objectID: "master_all_clicks"
							})

							analytics_index.partialUpdateObject({
								master_click_times: {
									_operation: 'Add',
									value: [
										currentStore?.organization,
										String(new Date()).split(" ").slice(0, 5).join(" ").slice(0, -3),
										currentStore?.objectID
									]
								},
								objectID: "master_volunteer_clicks"
							})
						}}
					>
						Volunteer
					</a>

					{/* <br></br> */}
					{/* <br></br> */}

					{/* <div className={'flex gap-2 my-2 flex-wrap'}>
						{
							currentStore?.category ?
								currentStore?.category?.constructor === Array ?
									currentStore?.category?.map((category: string) => (
										<span
											key={`${category}-${currentStore?.objectID}`}
											className={
												'bg-green-100 text-sm font-normal text-green-800 px-2 gap-13 rounded-full'
											}
										>
											{category}
										</span>
									))
									:
									<span
										key={`${currentStore?.category}-${currentStore?.objectID}`}
										className={
											'bg-green-100 text-sm font-normal text-green-800 px-2 gap-13 rounded-full'
										}
									>
										{currentStore?.category}
									</span>
								:
								<div></div>
						}
					</div> */}
					<p className={'font-light text-sm mt-1'}>
						{
							currentStore?.address ?
								currentStore?.address
									.replace(currentStore?.state, "")
									.replace(", " + currentStore?.country, "")
								: null
						}
					</p>
					<a className={'font-light text-sm \
          underline text-blue-600 hover:text-blue-800 visited:text-purple-600'}
						href={`tel:${currentStore?.phone}`}
					>
						{currentStore?.phone}
					</a>
					<br className={currentStore?.phone ? "" : "hidden"}></br>
					<a className={'font-light text-sm \
          underline text-blue-600 hover:text-blue-800 visited:text-purple-600'}
						href={'mailto:' + currentStore?.email}
						target="_blank"
					>
						{currentStore?.email}
					</a>
					<p className={'font-light text-sm flex'}>{currentStore?.contact}</p>

					{/* <p className={'font-light text-sm flex'}>{currentStore?.volunteerhours}</p> */}

					<br></br>

					{/* <p className={`${currentStore?.recurring ? "" : "hidden"} font-light text-sm`}>Recurring</p> */}
					{/* <p className={'font-normal text-sm'}>
						{formatDate(currentStore?.startdatetime)}
						{currentStore?.enddatetime && currentStore?.enddatetime !== currentStore?.startdatetime ?
							` - ${formatDate(currentStore?.enddatetime)}` : ""}
					</p>
					<p className={`${currentStore?.recurring || currentStore?.startdatetime || currentStore?.enddatetime ? "" : "hidden"}`}><br></br></p> */}

					<p className={'font-semibold text-lg text-green-700'}>Description</p>
					<p className={'font-normal text-sm'} style={{ whiteSpace: "pre-wrap" }}>{currentStore?.description}</p>
					<p className={`font-semibold text-lg text-green-700 ${!currentStore?.volunteerrequirements ? "hidden" : ""}`}><br></br>Volunteer Requirements</p>
					<p className={'font-normal text-sm'} style={{ whiteSpace: "pre-wrap" }}>{currentStore?.volunteerrequirements}</p>

					<p className={`font-semibold text-lg text-green-700 ${currentStore?.reviews? "" : "hidden"}`}><br></br>
						Reviews
					</p>
					<p className={`font-normal text-xl text-yellow-300`} style={{ whiteSpace: "pre-wrap" }}>
					{
						(
							currentStore?.totalstars != null && 
							1 <= currentStore?.totalstars / currentStore?.numreviews && 
							currentStore?.totalstars / currentStore?.numreviews <= 5
						) ?
							"★".repeat(Math.round(currentStore?.totalstars / currentStore?.numreviews))
								+ 
							"☆".repeat(5 - Math.round(currentStore?.totalstars / currentStore?.numreviews))
								: 
							""
					}
					</p>
					<p className={`font-normal text-lg`} style={{ whiteSpace: "pre-wrap" }}>
					{
						(
							currentStore?.totalstars != null && 
							1 <= currentStore?.totalstars / currentStore?.numreviews && 
							currentStore?.totalstars / currentStore?.numreviews <= 5
						) ?
							// round to 1 decimal place
							Math.round(currentStore?.totalstars / currentStore?.numreviews * 10) / 10 + " star, "
							+ currentStore?.numreviews + " review" + (currentStore?.numreviews > 1 ? "s" : "")
							: 
							""
					}
					</p>
					<br></br>
					<div>
						{
							// reverse review array to view most recent at
							currentStore?.reviews?.toReversed().map((currentReview: ReviewType) => (
								// [...currentStore?.reviews]?.reverse().map((currentReview: ReviewType) => (
							// currentStore?.reviews.map((currentReview: ReviewType) => (

								<div>
									<p className={'font-normal text-md text-yellow-300'} style={{ whiteSpace: "pre-wrap" }}>
										
										{						
											(
												currentReview?.stars != null && 
												1 <= currentReview?.stars && 
												currentReview?.stars <= 5
											) ?
						
											"★".repeat(currentReview?.stars) + "☆".repeat(5-currentReview?.stars)
											:
											""
										
										}
									</p>
									<p className={'font-normal text-sm inset-y-0 right-0'} style={{ whiteSpace: "pre-wrap" }}>
										{currentReview?.reviewer} on {currentReview?.reviewdate}
									</p>
									<p className={'font-normal text-sm'} style={{ whiteSpace: "pre-wrap" }}>
										"{currentReview?.review}"
									</p>

									<br></br>
								</div>
							))
						}
					</div>

					<form id="form" onSubmit={handleSubmit(onSubmit)}>
						<div className={'pb-2'}>
							<p className="flex font-medium text-lg text-green-700 py-1">Leave a Review</p>
							<textarea
								rows={4}
								maxLength={500}
								className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								placeholder="Write about your experience..."
								required
								{...register("review", {})}
							>
								{`${process.env.NODE_ENV === "development" ?
									"Write about your experience..."
									: ""}`}
							</textarea>
						</div>
						<div className="pb-2">
							<input
								type="text"
								placeholder="Name"
								required
								maxLength={50}
								{...register("reviewer", {})}
								className={'flex focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'}
							/>
						</div>
						<div className="pb-2">
							<input
								type="number"
								placeholder="Rating (1 to 5 stars)"
								required
								step="1"
								min="1"
								max="5"
								{...register("stars", {})}
								className={'flex focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'}
							/>
						</div>

				<ul className="flex relative">
					<input
						type="submit"
						className={'transform hover:scale-110 duration:100 bg-green-100 hover:cursor-pointer text-green-800 py-1 px-2 rounded-md'}
					// onClick={() => (document.getElementById("address") as HTMLInputElement).value = "hello"}
					/>
				</ul>

			</form>


			<br></br>
			{/* <div className={`${currentStore?.mapperfullname && currentStore?.mapperemail ? "" : "hidden"}`}>
					<p className={'font-light text-gray-500 text-sm'} style={{ whiteSpace: "pre-wrap" }}>
						{`Mapped by ${currentStore?.mapperfullname} on ${currentStore?.currentdatetime}`}
						<a className={'font-light text-gray-500 text-sm'}
						>
							{"on " + currentStore?.currentdatetime}
						</a>
					</p>
					</div> */}

			<div className={`${currentStore?.mapperfullname ? "" : "hidden"}`}>
				<p className={'font-light text-gray-500 text-sm'} style={{ whiteSpace: "pre-wrap" }}>
					{`Mapped by ${currentStore?.mapperfullname} on ${currentStore?.currentdatetime}`}
				</p>
			</div>

			<div className={`${currentStore?.moderator ? "" : "hidden"}`}>
				<p className={'font-light text-green-700 text-sm'} style={{ whiteSpace: "pre-wrap" }}>{`Moderated`}
					<a className={'font-light text-gray-500 text-sm'}
					>
						{" by " + currentStore?.moderator + " on " + currentStore?.moderatordate}
					</a>
				</p>
			</div>

			{/* <div className={`${currentStore?.mapperfullname ? "" : "hidden"}`}>
						<p className={'font-light text-gray-500 text-sm'} style={{ whiteSpace: "pre-wrap" }}>{`Mapped by ${currentStore?.mapperfullname}`}</p>
					</div> */}
			{/* <p className={`${currentStore?.currentdatetime ? "" : "hidden"} font-light text-gray-500 text-sm`}>{`Mapped on ${currentStore?.currentdatetime}`}</p> */}

			<a
				className={'font-light text-sm underline text-blue-600 hover:text-blue-800 hover:cursor-pointer visited:text-purple-600'}
				onClick={() => {
					report(currentStore);
				}}
			>
				Report
			</a>
			{/* <img style={{filter:"blur(8px)", WebkitFilter:"blur(8px)"}} src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80" width="160" height="160"></img> */}
		</div>
			</article >
		</div >
	);
};

export default StoreDetails;