import React, { useState, useCallback, useMemo } from 'react';
// import { ReactComponent as Logo } from '../../assets/helpmapmini.svg'
// import { ReactComponent as Logo } from '../../assets/healthcare-marker.svg'
import { ReactComponent as Logo } from '../../assets/helpmapmini.svg'
import { ReactComponent as LogoMobile } from '../../assets/helpmapmobilemini.svg'
import './App.css';
import { InstantSearch, Hits, Configure, RefinementList, SearchBox } from 'react-instantsearch-dom'
import { indexName, searchClient } from "../../lib/algoliaClient";
import { GeoHit } from "../../types/StoreHit";
import StoreComponent from "../StoreComponent/StoreComponent";
import StoreDetails from "../StoreComponent/StoreDetails";
//import Header from "../Header/Header";
import Map from "../Map/Map"
import Autocomplete from "../Autocomplete/Autocomplete"
import { MapboxAddressComponent, SuggestionComponent } from "../Autocomplete/AutocompleteComponents"
import { LngLat } from "../../types/LngLat";
import { createSuggestionsPlugin } from "../../AutocompletePlugins/query_suggestion_plugin"
import { createMapboxGeocodingPlugin } from "../../AutocompletePlugins/mapbox_geocoding_plugin"
import MenuBar from "../MenuBar/MenuBar";
import { Menu } from 'react-instantsearch-hooks-web';
//import MainForm from "../Header/AddStore";
import ParentModal from "../MenuBar/ParentModal";
import RefinementListMap from '../MenuBar/RefinementListMap';
// import { ModalForm } from '../Header/ModalForm';
import { todayint } from "./date"
import { daysInWeek } from 'date-fns';
import MapComponent from "../Map/Map"
import MenuButton from '../MenuBar/MenuButton';
import { search } from '@algolia/autocomplete-plugin-recent-searches';
import { convertCompilerOptionsFromJson } from 'typescript';
import { connectHitInsights } from 'react-instantsearch-dom';
import HitWithInsights from '../StoreComponent/StoreComponentInsights';
import HitWithInsights2 from '../StoreComponent/StoreComponentInsights2';

console.warn = () => { console.log("Error") };

function App() {

	const [searchState, setSearchState] = useState<Record<string, any>>({});
	const [currentStore, setCurrentStore] = useState<GeoHit | null>(null);
	//initial coordinates set to (99,99) and let aroundLatLngViaIP=true
	const [currentLat, setCurrentLat] = useState(99);
	const [currentLng, setCurrentLng] = useState(99);

	const [currentModal, setCurrentModal] = useState<string | null>
		// (process.env.NODE_ENV === "development" ? 'Add Opportunity' : null);
		// (process.env.NODE_ENV === "development" ? 'About' : null);
		(null);

	const [isUserInteraction, setIsUserInteraction] = useState(true);

	const modalButtons = ["About", "Add Opportunity", "Account", "Team"];
	// Handle search results updates

	// const onSubmit = useCallback(({ state }) => {
	//   setSearchState((searchState) => ({
	//     ...searchState,
	//     query: state.query,
	//   }));
	//   }, []);

	// // Click on the little cross on autocomplete field
	// const onReset = useCallback(() => {
	//   setSearchState((searchState) => ({
	//     ...searchState,
	//     query: '',
	//   }));
	// }, []);


	const [isIntiatedURLwithID, setIsIntiatedURLwithID] = useState(false);
	const pathname = window.location.pathname;
	const objectID = pathname.replace('/', '');
	if (!isIntiatedURLwithID && objectID.length > 0) {
		setIsIntiatedURLwithID(true);
		// console.log(objectID);
		const index = searchClient.initIndex(indexName);
		//index.getObject(objectID).then((objects) => console.log(objects)).catch();
		index.getObject<GeoHit>(objectID, {
			//  attributesToRetrieve: ['city', 'name']
			//}).then((object) => console.log(object.name)).catch();
		}).then((object) => setCurrentStoreByObject(object)).catch();
	}

	type record = {
		day: string[],
		objectID: string
	};

	const setCurrentStoreByObject = (object: GeoHit) => {
		//if (hits.length==1){
		//  const currentStore = hits[0];
		// console.log(object);
		setCurrentStore(object ? object : null);
		setCurrentLat(object._geoloc.lat);
		setCurrentLng(object._geoloc.lng);
	}
	// }
	const setCurrentStoreByMarker = (marker: LngLat, hits: GeoHit[]) => {
		const currentStore = hits.find(store => store._geoloc.lng == marker[0] && store._geoloc.lat == marker[1]);
		setCurrentStore(currentStore ? currentStore : null);

	}

	// Memoize plugins to then leverage useCallback hooks
	const plugins = useMemo(() => {
		const mapboxGeocodingPlugin = createMapboxGeocodingPlugin({
			fuzzyMatch: false,
			autocomplete: true,
			types: ['place'],
			country: ['CA'],
			access_token: process.env.REACT_APP_MAPBOX_TOKEN!,
		},
			MapboxAddressComponent,
			(item) => {
				setCurrentLng(item.geometry.coordinates[0]);
				setCurrentLat(item.geometry.coordinates[1]);
				setCurrentStore(null);
			}
		);

		const querySuggestionPlugin = createSuggestionsPlugin(
			searchClient,
			indexName as string,
			(query) => {
				setSearchState((searchState) => ({
					...searchState,
					query: query,
				}));
			},
			(item) => {
				setCurrentLng(item._geoloc.lng);
				setCurrentLat(item._geoloc.lat);
				setCurrentStore(null);
			},
			SuggestionComponent
		);

		// return [querySuggestionPlugin, mapboxGeocodingPlugin];
		return [querySuggestionPlugin];
	}, []);

	return (
		<div className=" w-full h-screen flex-col fixed">
			<InstantSearch searchClient={searchClient} indexName={indexName}>

				<Configure
					// aroundLatLngViaIP={currentLat == 99}
					// aroundLatLng={currentLat == 99 ? "" : currentLat + ',' + currentLng}
					// filters={`enddateint >= ${todayint} AND (status:success)`}
					filters={`enddateint >= ${todayint}`}
					// clickAnalytics
				/>
				<div className={'flex h-full w-full'}>
					{/* <div className={'hidden sm:flex flex-col w-1/3'}> */}
					<div className={`${currentStore ? 'hidden' : ''} sm:block flex sm:flex flex-col w-screen sm:w-1/3`}>
						<div className={'m-2 sticky top-0'}>

							<div className={'m-2'}>
								<Logo className={'scale-75 h-16 cursor-default'} style={{ marginBottom: "12px;" }} />
							</div>
							{/* <div><p>🏀</p></div> */}
							<ul className="bg-transparent flex mx-2 block sm:hidden font-medium">
								{/* <ul className="bg-transparent flex mx-2 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0"> */}
								<MenuButton
									name={"Add Opportunity"}
									text={"+ Opportunity"}
									onClick={setCurrentModal}
									currentModal={currentModal}
									onClick2={setCurrentStore}
								/>
							</ul>
							{/* <div className={'m-2 fill-green-700 text-green-700 hidden sm:block'}> */}
							{/* <div className={'m-2 fill-green-700 text-green-700'}>
								{<Autocomplete
									initialState={{
										query: searchState.query,
									}}
									placeholder={'Search opportunities'}
									openOnFocus={true}


									plugins={plugins}
								/>}
							</div> */}

						</div>

						<div className={'m-2 overflow-scroll'}>
							<Hits<GeoHit> hitComponent={hit => (
									<StoreComponent
										store={hit.hit}
										onClick={
											setCurrentStore
										}
										currentStore={currentStore}
										key={hit.hit.objectID}
										insights={HitWithInsights}
									/>
									)
								}
							/>
						</div>

						{/* <Hits hitComponent={HitWithInsights} /> */}

						{/* <div className={'m-2 overflow-scroll'}>
							<Hits hitComponent={
								// hit => (
								HitWithInsights2
								// )
							}
							/>
						</div> */}

					</div>

					{/* <div className={`flex flex-col w-full`}> */}
					<div className={`flex flex-col sm:w-full`}>

						{/* mobile logo */}
						{/* <div className={'m-2 absolute z-10 sm:hidden'}>
							<LogoMobile className={'h-16 cursor-default'} style={{ marginBottom: "12px;" }} />
						</div> */}

						<div className={`${currentStore ? 'block' : 'hidden'} absolute z-10 sm:mx-5 bg-white h-full overflow-scroll w-full sm:w-1/3`}>
							<StoreDetails currentStore={currentStore} onClick={() => setCurrentStore(null)} />
						</div>

						<div className={' hidden sm:block flex h-screen w-full z-0'}>
							<Map
								currentLngLat={[currentLng, currentLat]}
								currentStore={currentStore ? [currentStore._geoloc.lng, currentStore._geoloc.lat] : null}
								// currentStore={[-79, 43]}
								onClickMarker={setCurrentStoreByMarker} 
								// center={[-79, 43]}
							/>
						</div>

						<div className={` hidden sm:block position: absolute w-fulltop-4 bg-transparent rounded md:w-auto`}>
							<MenuBar
								onClick={setCurrentModal}
								currentModal={currentModal}
								onClick2={setCurrentStore}
							/>
						</div>

						<RefinementListMap
							currentModal={currentModal}
							onClick={() => setCurrentModal(null)}
						/>

						<div className={`${currentModal
							&& modalButtons.includes(currentModal) ? '' : 'hidden'}`}>
							<ParentModal
								currentModal={currentModal}
								onClick={() => setCurrentModal(null)}
							/>
						</div>

					</div>
				</div>

			</InstantSearch>

		</div>
	);

}



export default App;
