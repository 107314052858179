import { RefinementList } from "react-instantsearch-dom";
import { ClearRefinements } from 'react-instantsearch-dom';

const RefinementListElement = ({
	attribute,
    operator
}: {
	attribute: string;
	operator: "and" | "or" | null;
}): JSX.Element => {
    return (
        <div>
            <RefinementList
                attribute={attribute}
                operator={operator ? operator : "or"}
                className={'cursor-pointer'}
                limit={15}
            />
            <br></br>
        </div>
    );
};

export default RefinementListElement;