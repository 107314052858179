import React from 'react';
// import style from './style.scss';

import AutoAddressCompleteResultItem from './AutoAddressCompleteResultItem';

const AutoAddressCompleteResult = (props) => {

    const handleSelect = (item) => {
        props.handleClick(item);
    }

    const matchedItems = props.items.map((item) => {
        return <AutoAddressCompleteResultItem
            key={item.id}
            placeName={item.place_name}
            id={item.id}
            selectPlace={handleSelect.bind(null, item)}
        />

    });
    const display = props.items.length > 0 ? "block" : "none";

    return (<ul className="places-litm-el" style={{ display: display }}>{matchedItems}</ul>);
}

export default AutoAddressCompleteResult;