import { RefinementList } from "react-instantsearch-dom";
import { ClearRefinements } from 'react-instantsearch-dom';
import RefinementListElement from "./RefinementListElement";
// import { clearRefinements } from 'instantsearch.js/es/widgets';
// import ScriptTag from 'react-script-tag';

const Modal = ({
	currentModal,
	onClick
}: {
	currentModal: string | null;
	onClick: () => void;
}): JSX.Element => {
	return (
		<div
			className={
				`${currentModal ? '' : 'hidden'}
				position: absolute w-60 ml-5 inset-x-50 bg-white rounded-lg top-24 inline-block`
			}
		>
			<ClearRefinements
				className={"rounded-t-lg select-none bg-white text-green-700 hover:text-green-900 cursor-pointer block py-1 px-2"}
			/>

			<div className={`${currentModal === "Category" ? "" : "hidden"}`}>
				<RefinementListElement
					attribute="category"
					operator="and"
				/>
			</div>

			<div className={`${currentModal === "Month" ? "" : "hidden"}`}>
				<RefinementListElement
					attribute="month" 
					operator="or"
				/>
			</div>

			<div className={`${currentModal === "Day" ? "" : "hidden"}`}>
				<RefinementListElement
					attribute="day" 
					operator="or"
				/>
				<RefinementListElement
					attribute="hoursperday"
					operator="or"
				/>
			</div>

			<div className={`${currentModal === "Attendance" ? "" : "hidden"}`}>
				<RefinementListElement
					attribute="attendance" 
					operator="or"
				/>
			</div>

		</div>
	)
};

export default Modal;