import emailjs from "@emailjs/browser";
import filter from "./filterFunction"

function EmailReport(
    newObject: any,
    cleanObject: boolean,
    status: string,
    profanityScore: number,
    profanityCount: number
) {
    let newObjectPrint = "";
    for (let i = 0; i < newObject.values; i++) {
        newObjectPrint += newObject.values[i] + "\n";
    }

    let objectReplace = 
    JSON.stringify(newObject)
    .replaceAll(',"',',\n\n"')
    .replaceAll('":','":    ')
    .replaceAll("{","{\n\n")
    .replaceAll("}","\n\n}")
    .replaceAll("\\n","\n")

    if (cleanObject) {
        objectReplace = filter.clean(objectReplace);
    }

    const templateParams = {
        status: status,
        profanityScore: Math.round(profanityScore * 100) / 100,
        profanityCount: Math.round(profanityCount * 100) / 100,
        name: filter.clean(newObject.mapperfullname
            + " <" + newObject.mapperemail + ">"),
        org_name: filter.clean(newObject.organization),
        image: cleanObject ? "" : newObject.image,
        image2: <img style={{filter:"blur(8px)", WebkitFilter:"blur(8px)"}} src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80" width="160" height="160"></img>,
        object: objectReplace
    };
    
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLICKEY as string);
    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
        templateParams)
        .then(function (response) {
            // console.log('SUCCESS!', response.status, response.text);
        }, function (error) {
            // console.log('FAILED...', error);
        });
}

export default EmailReport;