
import { ReactComponent as ShopCenter } from '../../assets/shop-center.svg';
import React, { useEffect, useState } from 'react';
import { GeoHit } from "../../types/StoreHit";
import { LngLat } from "../../types/LngLat";
import { Marker } from 'react-mapbox-gl';
import centerMapOnCoordinates from "../Map/Map";
import formatDate from '../App/formatDate';
import { connectHitInsights } from 'react-instantsearch-dom';
import { indexName, searchClient } from "../../lib/algoliaClient";

const StoreComponent = ({
  store,
  onClick,
  currentStore,
  key,
  insights
}: {
  store: GeoHit;
  onClick: (store: GeoHit) => void;
  currentStore?: GeoHit | null;
  key: any;
  insights: any | null;
}): JSX.Element => {

  const [isSelected, setSelected] = useState(false);

  const index = searchClient.initIndex(indexName);
  const analytics_index = searchClient.initIndex("helpmap_user_analytics");

  useEffect(() => {
    setSelected(currentStore?.objectID === store.objectID)
  }, [currentStore])



  //  onClick(store);

  return (
    <article
      className={`${isSelected ? 'bg-green-50' : 'bg-white hover:bg-gray-100'} overflow-hidden cursor-pointer m-2`}
      onClick={() => {
        onClick(store);

        // // update click tracking with Algolia API
        // insights('clickedObjectIDsAfterSearch', {
        //   eventName: 'Product Clicked'
        // })

        // update opportunity engagement statistics
        index.partialUpdateObject({
          clicks: {
            _operation: 'Increment',
            value: 1
          },
          totalclicks: {
            _operation: 'Increment',
            value: 1
          },
          objectID: store.objectID
        })

        analytics_index.partialUpdateObject({
          total_all_clicks: {
            _operation: 'Increment',
            value: 1
          },
          total_opportunity_clicks: {
            _operation: 'Increment',
            value: 1
          },
          objectID: "master_all_clicks"
        })

        analytics_index.partialUpdateObject({
          master_click_times: {
            _operation: 'Add',
            value: [
              store.organization,
              String(new Date()).split(" ").slice(0, 5).join(" ").slice(0, -3),
              store.objectID
            ]
          },
          objectID: "master_opportunity_clicks"
        })
      }}
    >

      <img src={store.image}
        className={`flex object-cover w-full h-32
                    ${store.image ? "" : "hidden"}`}
      ></img>
      <div className="sm:p-2 my-2">
        <h2 className={'font-medium text-lg'}>{store.organization}</h2>

        <p className={`font-normal text-md text-yellow-300`}>
        <a className={`font-normal text-sm text-black`}>{
						(
							store?.totalstars != null && 
							1 <= store?.totalstars / store?.numreviews && 
							store?.totalstars / store?.numreviews <= 5
						) ?
              // round to 1 decimal place
              Math.round(store?.totalstars / store?.numreviews * 10) / 10 + " "
              :
              ""
          }
          </a>
          {
						(
							store?.totalstars != null && 
							1 <= store?.totalstars / store?.numreviews && 
							store?.totalstars / store?.numreviews <= 5
						) ?
              "★".repeat(Math.round(store?.totalstars / store?.numreviews))
              +
              "☆".repeat(5 - Math.round(store?.totalstars / store?.numreviews))
              :
              ""
          }
          <a className={`font-normal text-sm text-black`}>{
            store?.totalstars ?
              // round to 1 decimal place
              " (" + store?.numreviews + ")"
              :
              ""
          }
          </a>
        </p>
        <p className={'font-normal text-sm'}>
          {store.volunteerposition}
        </p>
        <p className={'font-normal text-sm'}>
          {store.location}
        </p>
        {/* <p className={'font-normal text-sm'}>
          {formatDate(store.startdatetime)}
          {store.enddatetime && store.enddatetime !== store.startdatetime ?
            ` - ${formatDate(store.enddatetime)}` : ""}
        </p> */}
      </div>
    </article>
  );
};

const aa = require("search-insights");

aa('init', {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
  useCookie: true,
});


interface Window {
  aa: any;
}

let userToken = '';
aa('getUserToken', null, (err: any, algoliaUserToken: string) => {
  if (err) {
    console.error(err);
    return;
  }
  // console.log("USERTOKEN: " + algoliaUserToken);
  userToken = algoliaUserToken;
});

// export default connectHitInsights(aa)(StoreComponent);
export default StoreComponent;
