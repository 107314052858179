const Profile = ({
    name,
    email,
    roles,
    exRoles,
    image,
    bio
}: {
    name: string;
    email: string;
    roles: string[];
    exRoles: string[];
    image: string;
    bio: string
}
) => {
    return (
        // <div className="justify-center items-center">
        <div className="w-60 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6">
            {/* <div className="flex justify-center items-center"> */}
                <img src={image}
                    className="object-cover h-60 w-120 my-5 shadow-lg flex"
                    alt={name}
                ></img>
            {/* </div> */}
            {/* <div className="w-60"> */}
            <div>
                {/* <div className="flex justify-center items-center"> */}
                <p className={'font-medium text-xl'}>{name}</p>
                {/* </div> */}
                {/* <div className="flex justify-center items-center"> */}
                <a className={'font-light text-sm \
                    underline text-blue-600 hover:text-blue-800 visited:text-purple-600 \
                    hidden lg:block'}
                    href={'mailto:' + email}
                    target="_blank"
                >
                    {email}
                </a>
                <div className={'flex gap-2 my-2 flex-wrap'}>
                    {roles.map((role: string | number) => (
                        <div>
                            <p
                                key={`${role}-${name}`}
                                className={'bg-green-50 text-sm font-normal text-green-800 px-2 gap-13 rounded-full'}
                                // className="font-light text-xs"
                            >
                                {role}
                            </p>
                        </div>
                    ))}
                </div>
                <div className={'flex gap-2 my-2 flex-wrap'}>
                    {exRoles.map((exRole: string | number) => (
                        <div>
                            <p
                                key={`${exRole}-${name}`}
                                className={'bg-blue-50 text-sm font-normal text-blue-800 px-2 gap-13 rounded-full hidden lg:block'}
                                // className="font-light text-xs"
                            >
                                {exRole}
                            </p>
                        </div>
                    ))}
                </div>
                {/* <p className={'font-normal text-sm'}>{bio}</p> */}
            </div>

        </div>
    )
};

export default Profile;