import TeamModal from "../Modals/TeamModal/TeamModal"
import AddOpportunityModal from "../Modals/AddOpportunityModal/AddOpportunityModal"
import { InstantSearch, Hits, Configure, RefinementList, SearchBox } from 'react-instantsearch-dom'

const Modal = ({
  currentModal,
  onClick
}: {
  currentModal: string | null;
  onClick: () => void;
}): JSX.Element => {
  return (
    <div>
      <article className={'absolute z-10 bg-white w-full h-full overflow-scroll absolute inset-y-0 right-0'}>
        <button type="button"
          className="absolute cursor-pointer right-0 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
          onClick={() => onClick()}>
          <span className="sr-only">Close menu</span>

          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>

        </button>
        {/* <div className={`${currentModal === 'About' ? '' : 'hidden'}`}>
          <AboutModal />
        </div> */}
        <div className={`${currentModal === 'Add Opportunity' ? '' : 'hidden'}`}>
          <AddOpportunityModal />
        </div>
        <div className={`${currentModal === 'About' ? '' : 'hidden'}`}>
          <TeamModal />
        </div>
      </article>
    </div>
  );
};

export default Modal;