import { ReactComponent as Logo } from '../../assets/helpmap5min.svg'
import MenuButton from './MenuButton';
import MenuButtonArray from './MenuButtons.json'
import { GeoHit } from "../../types/StoreHit";

const MenuBar = (
    {
        onClick,
        currentModal,
        onClick2,
    }:
        {
            onClick: (name: string | null) => void;
            currentModal: string | null;
            onClick2: (currentStore: GeoHit | null) => void;
        }
) => {
    return (

        // <nav>
        //     <div className="container flex flex-wrap justify-between items-center mx-auto">

        //         <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
        //             <span className="sr-only">Open main menu</span>
        //             <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        //         </button>
        // <div className="hidden w-full md:block md:w-auto" id="navbar-default">
        <ul className="bg-transparent flex py-4 px-5 md:flex-row space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0">
            {/* {MenuButtonArray.map((MenuButtonElement: any) => ( */}
            <MenuButton
                name={"Category"}
                onClick={(name: string | null) => onClick(name)}
                currentModal={currentModal}
                onClick2={(currentStore: GeoHit | null) => onClick2(currentStore)}
            />
            {/* ))} */}
            {/* <MenuButton
                name={"Attendance"}
                onClick={(name: string | null) => onClick(name)}
                currentModal={currentModal}
                onClick2={(currentStore: GeoHit | null) => onClick2(currentStore)}
            /> */}
            <div className="hidden md:block">
                <MenuButton
                name={"Add Opportunity"}
                onClick={(name: string | null) => onClick(name)}
                currentModal={currentModal}
                onClick2={(currentStore: GeoHit | null) => onClick2(currentStore)}
            />
            </div>
            <MenuButton
                name={"About"}
                onClick={(name: string | null) => onClick(name)}
                currentModal={currentModal}
                onClick2={(currentStore: GeoHit | null) => onClick2(currentStore)}
            />
        </ul>
        // </div>
        //     </div>
        // </nav>

    )
};

export default MenuBar;