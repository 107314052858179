import { Configure, Hits, connectHitInsights } from 'react-instantsearch-dom';

const StoreComponentInsights = ({ hit, insights }:{hit:any; insights:any;}) => (
  <article>
    <h1>{hit.name}</h1>
    <button
      onClick={() =>
        insights('clickedObjectIDsAfterSearch', {
          eventName: 'Add to favorite'
        })
      }
    >
      Add to favorite
    </button>
  </article>
);

const aa = require("search-insights");

aa('init', {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
  useCookie: true,
});

interface Window {
  aa: any;
}

let userToken = '';
aa('getUserToken', null, (err: any, algoliaUserToken: string) => {
  if (err) {
    console.error(err);
    return;
  }
  // console.log("USERTOKEN: " + algoliaUserToken);
  userToken = algoliaUserToken;
});

const HitWithInsights = connectHitInsights(aa)(StoreComponentInsights);



export default HitWithInsights;