import Profile from "./Profile"
import ProfileTeam from "./ProfileTeam"
import members from "./Members.json";
import { ReactComponent as Logo } from '../../../assets/helpmapmini.svg'

const Modal = (): JSX.Element => {
	return (
		<div className="pl-6 pt-6 top-0">
			{/* <div className={'m-2'}>
				<Logo className={'h-16 cursor-default'} style={{ marginBottom: "12px;" }} />
			</div> */}

			<h2 className={'font-medium text-2xl'}>About</h2>
			{/* <br></br> */}
			<div className={'flex flex-row'}>
				<div className="hidden lg:block w-1/2">
					{/* <p className={'font-normal text-lg text-green-500'}>
						for students, by students
					</p>
					<p className={'font-normal text-lg text-green-500'}>
						Find Volunteer Opportunities Near You!
					</p> */}
					{/* <br></br> */}


					{/* <div className={'flex grid grid-cols-3 space-x-8'}> */}

					<div>
						{/* <p className={'font-medium text-3xl text-green-700'}>Our Mission</p>
						<img src={"https://students.1fbusa.com/hubfs/25%20Ways%20to%20Volunteer%20in%20Your%20Community.jpg"}
							className="object-cover h-48 w-96 rounded-lg my-5 shadow-lg flex"
						></img> */}
						<br></br>
						<p className={'font-normal text-md mr-2'}>
							{`
						HelpMap is a service platform for students to find, filter, share, review, and add volunteer opportunities.
						We want students to contribute to the world with meaningful service experiences.
						`}
						</p>
						<br></br>
						<a href="https://youtube.com/@HelpMapVolunteer" className="text-red-600 hover:text-red-800 visited:text-purple-600" target="_blank">HelpMap Youtube</a> |
						<a href="https://instagram.com/helpmap.ca" className="text-pink-600 hover:text-pink-800 visited:text-purple-600" target="_blank"> HelpMap Instagram</a> |
						<a href="https://linkedin.com/company/helpmap" className="text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank"> HelpMap Linkedin</a>
						{/* <br></br>
						<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,700,0,0" />
						<span className="material-symbols-outlined">
							search
						</span> */}
						{/* <a
							className={'bg-green-100 font-bold text-green-800 p-3 my-8 rounded-md'}
							href={'https://forms.gle/eJyDoUn6HDDztZx28'}
							target="_blank"
						>
							Join Us!
						</a>
						<br /><br />
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
							<path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
						</svg> */}
					</div>
				</div>
				{/* <div></div> */}
				{/* <div className="flex justify-center items-center m-4"> */}
				{/* <h2 className={'font-medium text-xl text-green-700'}>Cofounders</h2> */}
				{/* </div> */}
				{/* <div className={'flex grid grid-cols-4 gap-4'}> */}

				<div className="">
					{/* <p className={'font-medium text-3xl text-green-700'}>Cofounders</p><br></br>
					<div className="hidden sm:block">
						{members.slice(0, 2).map((member: any) => (
							<Profile
								name={member.name}
								email={member.email}
								roles={member.roles}
								exRoles={member.exRoles}
								image={member.image}
								bio={member.bio}
							/>
						))}
					</div> */}

					<div className="flex gap-2 content-center my-2 flex-wrap sm:hidden">
						{members.slice(0, 4).map((member: any) => (
							<ProfileTeam
								name={member.name}
								email={member.email}
								roles={member.roles}
								exRoles={member.exRoles}
								image={member.image}
								bio={member.bio}
							/>
						))}
					</div>

				</div>
			</div>
			<br></br>

			<p className={'font-medium text-3xl'} style={{ whiteSpace: "pre-wrap" }}>
				{`Meet Our `}<a className={'font-medium text-3xl text-green-700 underline decoration-4'}>A-Team</a>
			</p>
			<div className="flex gap-2 content-center my-2 flex-wrap">
				{members.map((member: any) => (
					<ProfileTeam
						name={member.name}
						email={member.email}
						roles={member.roles}
						exRoles={member.exRoles}
						image={member.image}
						bio={member.bio}
					/>
				))}
			</div>

		</div >
	);
};

export default Modal;