import statusModals from "./statusModals.json"
import { indexName, searchClient } from "../../../lib/algoliaClient";
import EmailReport from "./EmailReport";
import filterStats from "./filterStats"

const index = searchClient.initIndex(indexName);

const StatusModal = (
    {
        status, 
        onClickNext,
        objectID,
        onClickUndo
    }: 
    {
        status: number, 
        onClickNext: (i: number) => void,
        objectID: string,
        onClickUndo: (s: string) => void
    }
    ): 
    JSX.Element => {

    if (status) {
        const statusModal = statusModals[status];
        const color = [,"rgb(34 197 94)","rgb(234 179 8)","rgb(185 28 28)"][status];
        const bgcolor = [,"rgb(134 239 172)","rgb(253 224 71)","rgb(248 113 113)"][status];
        return (
            <div className="w-full h-full place-items-center bg-green-500 p-3"
                style={{backgroundColor: bgcolor}}
            >
                <h2 className={'font-medium text-2xl text-red-900'}
                    style={{color:color}}
                >
                    {statusModal.label}
                </h2><br></br>
                <p>{statusModal.line1}</p>
                <p>{statusModal.line2}</p>
                <br></br>
                {/* <img src={statusModal.image}></img><br></br> */}
                <img src={statusModal.image}
                    className="flex hidden sm:block object-cover w-full h-48"
                ></img><br></br><br></br>

                <button type="button" onClick={() => {
                    (document.getElementById("form") as HTMLFormElement).reset();
                    onClickNext(0);
                }} className={`${status !== 3 ? "" : "hidden"} w-full bg-white hover:cursor-pointer font-bold text-green-800 p-3 rounded-md`}>Next</button><br></br><br></br>
                
                <button type="button" onClick={() => {
                    

                    index.getObject(objectID).then(object => {
                        // console.log("getObject: ");
                        // console.log(object);

                        const filteredObject: any = filterStats(object);
                        const profanityCount = filteredObject.profanityCount;
                        const profanityScore = filteredObject.profanityScore;

                        EmailReport(object, true, "UNDO - CLEAN", profanityScore, profanityCount); 
                        EmailReport(object, false, "UNDO - RAW", profanityScore, profanityCount); 
                    });
            
                    onClickUndo(objectID);
                    onClickNext(0);
                }}
                    className={`${status === 1 ? "" : "hidden"} w-full bg-white hover:cursor-pointer font-bold text-green-800 p-3 rounded-md`}>Undo</button>
            </div>
        );
    }
    else {
        return <div></div>
    }

};

export default StatusModal;