import { useState } from "react";
import { useForm } from "react-hook-form";
import AutoAddressComplete from '../../AutoAddressComplete/AutoAddressComplete';
import inputArray from "./inputArray.json"
import checklistArray from "./checklistArray.json"
import SubmitOpportunity from "./SubmitOpportunity"
import StatusModal from "./StatusModal";
import DeleteOpportunity from "./DeleteOpportunity";
import { todaydatetime, todaynumeric, nextyearnumeric, todaytext } from "../../App/date"
import { indexName, searchClient } from "../../../lib/algoliaClient";
import calculateMonths from "./calculateMonths";
import confirmReset from "./confirmReset";

const index = searchClient.initIndex(indexName);

type inputElementType = {
	optional?: boolean;
	label: any,
	type?: string,
	displayValue: string,
	pattern?: string,
	attribute?: any,
	title?: string,
	id?: string,
	info?: string,
	emoji?: string
}

type FormValues = {
	organization: string,
	email: string,
	mapperfullname: string,
	mapperemail: string,
	mapperpassword: string,
	// lastName: string,
	phone: string,
	contact: string,
	image: string,
	volunteerposition: string,
	description: string,
	volunteerrequirements: string,
	volunteerhours: number,
	recurring: boolean,
	startdatetime: string,
	enddatetime: string,
	location: string,
	// age: string,
	website: string,
	address: string,
	coordinates: string,
	day: string[],
	hoursperday: string[],
	year: number[],
	attendance: string[],
	month: string[],
	category: string[],
	currentdatetime: string,
	objectID: string,
};

const AddOpportunityModal = (): JSX.Element => {

	const onSubmit = (data: FormValues) => {

		const newObject = {
			organization: data.organization,
			city: city,
			state: "Ontario",
			country: country,
			address: address,

			hoursperday: data.hoursperday,
			day: data.day,
			// month: data.month,
			month: calculateMonths(data.startdatetime, data.enddatetime),
			year: data.year,
			attendance: data.attendance,
			category: data.category,
			_geoloc: {
				lng: Number(((String(coordinates)).split(","))[0]),
				lat: Number(((String(coordinates)).split(","))[1])
			},
			website: data.website,
			mapperfullname: data.mapperfullname,
			mapperemail: data.mapperemail,
			mapperpassword: data.mapperpassword,
			phone: data.phone,
			contact: data.contact,
			email: data.email,
			image: data.image,
			volunteerposition: data.volunteerposition,
			description: data.description,
			volunteerrequirements: data.volunteerrequirements,
			volunteerhours: data.volunteerhours,
			recurring: data.recurring,
			startdatetime: data.startdatetime,
			enddatetime: data.enddatetime,
			enddateint: data.enddatetime ?
				parseInt(data.enddatetime.replaceAll("-", ""))
				: 3000_00_00,
			location: data.location,

			currentdatetime: todaytext,
			currentdatetimefull: Date(),
			objectID: String(Date.now()),
			// recurring: boolean,
		}

		const checklistArrays = [
			// [data.day, "Day"],
			// [data.hoursperday, "Hours Per Day"],
			[data.attendance, "Attendance"],
			[data.category, "Category"],
		];



		let falseArrays = "Please choose option(s) from:\n";

		if (!(city && country)) {
			alert("Please select an address");
		}

		else if (

			// if (
			process.env.NODE_ENV === "production"
			&& !checklistArrays.every(element => element[0])
		) {
			checklistArrays.forEach((checklistArray) => {
				if (!checklistArray[0]) {
					falseArrays += "- " + checklistArray[1] + "\n";
				}
			})
			alert(falseArrays);
		}

		else {
			const status = SubmitOpportunity(newObject);
			setStatusModal(status);
			setObjectID(newObject.objectID);
			index.getObject(newObject.objectID).then(object => {
				// console.log("getObject: ");
				// console.log(object);
			});
			(document.getElementById("address") as HTMLInputElement).value = "hello";
		}
	};
	const [statusModal, setStatusModal] = useState<number>(0);
	// const [statusModal, setStatusModal] = useState<number>(1);
	const [objectID, setObjectID] = useState<string>("");

	const [city, setCity] = useState("");
	const [country, setCountry] = useState("");
	const [address, setAddress] = useState("");
	const [coordinates, setCoordinates] = useState("");
	const setGeoInfo = (city: string, country: string, address: string, coordinates: string) => {
		setCity(city);
		setCountry(country);
		setAddress(address);
		setCoordinates(coordinates);
	}

	const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({});

	return (
		<article className={'flex p-6 h-full w-full'}>

			<div className={`${statusModal ? "" : "hidden"} relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg`}>
				<StatusModal
					status={statusModal}
					onClickNext={setStatusModal}
					objectID={objectID}
					onClickUndo={DeleteOpportunity}
				/>
			</div>

			<form id="form" onSubmit={handleSubmit(onSubmit)} className={`${statusModal ? "hidden" : ""}`}>
				<h2 className={'font-medium text-2xl'}>Add Opportunity</h2><br></br>

				<p>Earn <b>1</b> Service Hour per <b>3</b> Opportunities Mapped</p>
				<a
					className={'text-green-600 hover:text-green-800 hover:cursor-pointer visited:text-purple-600'}
					onClick={() => {
						alert("Add a known opportunity or research a new one\nCopy and Paste info from organization's volunteer page\nAsterisk* is required, No Asterisk is recommended\n\nNo service hours rewarded for very low quality opportunities.");
					}}
				>
					{`How to Map `}
				</a><a>|</a>
				<a
					className={'text-green-600 hover:text-green-800 hover:cursor-wait visited:text-purple-600'}
					onClick={() => {
						alert('Image Link:\n- Use official images of volunteers in action\n- Use general images if official image not found\n\nVolunteer Position(s):\n- Role A / Role B / Role C for multiple roles\n- Use "Multiple Roles" for 3+ roles\n\nLocation:\n- Many: Use "Multiple Locations"\n\nAddress:\n- Many: Enter Headquarters / One of the addresses\n\nDescription:\n- Introduce organization and volunteer role(s)\n\nVolunteer Requirements:\n- Must be accessible to high school students');
					}}
				>
					{` Mapper Guidelines `}
				</a>
				<a>|</a>
				<div className="block sm:hidden"></div>
				<a
					className={'text-green-600 hover:text-green-800 hover:cursor-pointer visited:text-purple-600'}
					onClick={() => {
						alert("Use ⌘C and ⌘V to Copy / Paste\nUse tab to jump to next form field\nUse ⌃(⇧)tab to toggle tabs\n");
					}}
				>
					{` Advanced Shortcuts`}
				</a>
				<br></br>
				<div>
					{
						inputArray.slice(0, inputArray.length - 1).map((inputElement: inputElementType) => (
							<div className={'m2'}>
								<p
									className="flex font-medium text-lg text-green-700 py-1"
								>
									{inputElement.emoji} {inputElement.label}{!inputElement.optional ? "*" : ""}
								</p>
								<p className={'text-gray-700 font-normal text-sm'}>{inputElement.info}</p>
								<input
									type={inputElement.type ? inputElement.type : "text"}
									defaultValue={
										process.env.NODE_ENV === "development" ?
											inputElement.displayValue : undefined
									}
									placeholder={inputElement.displayValue}
									pattern={inputElement.pattern}
									required={inputElement.optional ? false : true}
									title={inputElement.title}
									maxLength={inputElement.type !== "url" ? 50 : 524288}
									{...register(inputElement.attribute ?
										inputElement.attribute :
										inputElement.label.split(' ')[0].toLowerCase(), {
										// validate: (value: string) => value.length >= 3
									})}
									className={'flex focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'}
								/>

								{/* {errors.organization && <p>Name required</p>} */}
							</div>
						))
					}
				</div>

				<div className={'m2'}>
					<p className="flex font-medium text-lg text-green-700 py-1">
						📅 Start Date
					</p>
					<p className={'text-gray-700 font-normal text-sm'}>Leave blank for no start date</p>
					<input
						type="date"
						// defaultValue={
						// 	process.env.NODE_ENV === "development" ?
						// 		todaynumeric : undefined
						// }
						// placeholder={todaynumeric}
						min={todaynumeric}
						max={nextyearnumeric}
						required={false}
						{...register("startdatetime", {
						})}
						className={'flex focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'}
					/>
				</div>

				<div className={'m2'}>
					<p className="flex font-medium text-lg text-green-700 py-1">
						📅 End Date
					</p>
					<p className={'text-gray-700 font-normal text-sm'}>Leave blank for no end date</p>
					<input
						type="date"
						min={todaynumeric}
						max={nextyearnumeric}
						required={false}
						{...register("enddatetime", {
						})}
						className={'flex focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'}
					/>
				</div>

				<div className={'m2'}>
					<AutoAddressComplete onSelect={setGeoInfo} />
				</div>

				{/* <input onClick={() => (document.getElementById("address") as HTMLInputElement).value = "hello"}>Clear input field</input> */}

				<div className={'m2'}>
					<p className="flex font-medium text-lg text-green-700 py-1">💬 Description*</p>
					<textarea
						rows={4}
						maxLength={2000}
						className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
						placeholder="A Walk for the Ages in Support of Baycrest aims to raise awareness and funds in support of our quest to defeat dementia. If you’re looking for a fun, family-friendly event which also supports a great cause, A Walk for the Ages is for you! It’s all about joining together to improve brain health, prevent and effectively treat dementia, and allowing everyone to age fearlessly."
						required
						{...register("description", {
							// validate: (value: string) => false
							// validate: (value: string) => ((value.length >= 300) && (value.length <= 600))
						})}
					>
						{`${process.env.NODE_ENV === "development" ?
							"A Walk for the Ages in Support of Baycrest aims to raise awareness and funds in support of our quest to defeat dementia. If you’re looking for a fun, family-friendly event which also supports a great cause, A Walk for the Ages is for you! It’s all about joining together to improve brain health, prevent and effectively treat dementia, and allowing everyone to age fearlessly."
							: ""}`}
					</textarea>
					{/* {errors.description && <p>Description must be between 300 and 600 characters long</p>} */}
				</div>

				<div className={'m2'}>
					<p className="flex font-medium text-lg text-green-700 py-1">📐 Volunteer Requirements</p>
					<textarea
						rows={7}
						maxLength={2000}
						className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
						placeholder={`All participants must pay a registration fee.\n\nIndividuals 18 years of age and over must reach the fundraising minimum of $150 to participate.\n\nIndividuals 17 and under do not have a minimum to reach but are encouraged to fundraise.\n\nChildren under the age of fifteen (15) must be supervised by a parent or guardian during the Event.`}
						{...register("volunteerrequirements", {
						})}
					>
						{`${process.env.NODE_ENV === "development" ?
							`All participants must pay a registration fee.\n\nIndividuals 18 years of age and over must reach the fundraising minimum of $150 to participate.\n\nIndividuals 17 and under do not have a minimum to reach but are encouraged to fundraise.\n\nChildren under the age of fifteen (15) must be supervised by a parent or guardian during the Event.`
							: ""}`}
					</textarea>
					{/* {errors.volunteerrequirements && <p>Volunteer Requirements must be between 300 and 500 characters long</p>} */}
				</div>

				<div>
					{
						checklistArray.map((checklist: any) => (
							<div className={'m2'}>
								<fieldset className={'ais-RefinementList'}>
									<p className="flex font-medium text-lg text-green-700 py-1" >{checklist.emoji} {checklist.label}{!checklist.optional ? "*" : ""}</p>
									<ul className={`${checklist.label !== 'Category' ? "flex flex-row" : ""}`}>
										{checklist.list.map(
											(c: string, i: number) =>
												<li className={'m2'} key={c}>
													<input type="checkbox" value={c}
														{...register(checklist.label.replaceAll(' ', '').toLowerCase(), {})} />
													{c}
												</li>
										)}
									</ul>
								</fieldset>
							</div>
						))
					}
				</div>

				<div>
					{
						inputArray.slice(inputArray.length - 1).map((inputElement: inputElementType) => (
							<div className={'m2'}>
								<p
									className="flex font-medium text-lg text-green-700"
								>
									{inputElement.emoji} {inputElement.label}{!inputElement.optional ? "*" : ""}
								</p>
								<input
									type={inputElement.type ? inputElement.type : "text"}
									defaultValue={
										process.env.NODE_ENV === "development" ?
											inputElement.displayValue : undefined
									}
									placeholder={inputElement.displayValue}
									pattern={inputElement.pattern}
									required={inputElement.optional ? false : true}
									title={inputElement.title}
									maxLength={inputElement.type !== "url" ? 50 : 524288}
									{...register(inputElement.attribute ?
										inputElement.attribute :
										inputElement.label.split(' ')[0].toLowerCase(), {
										// validate: (value: string) => value.length >= 3
									})}
									className={'flex focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'}
								/>

								{/* {errors.organization && <p>Name required</p>} */}
							</div>
						))
					}
				</div>

				{/* <ul className="flex py-4 md:flex-row md:mt-0 md:text-sm md:font-medium md:border-0"> */}
				<ul className="flex py-4 relative">
					<input
						type="submit"
						className={'bg-green-100 hover:cursor-pointer font-bold text-green-800 p-3 rounded-md'}
						// onClick={() => (document.getElementById("address") as HTMLInputElement).value = "hello"}
					/>
					<input 
						type="button"// type="reset" 
						value="Reset"
						className={'bg-green-100 hover:cursor-pointer font-bold text-green-800 p-3 rounded-md absolute right-0'}
						onClick={() => {
							if (window.confirm("Reset form?")) {
								(document.getElementById("form") as HTMLFormElement).reset();
							}
						}}
						
					/>
				</ul>
				<div>
					<p className="flex font-medium text-lg text-green-700 py-1">
						Thanks for your contributions!
					</p>
				</div>
				<br></br><br></br>

			</form>
		</article >
	);
};

export default AddOpportunityModal;
